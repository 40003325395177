import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"
import DefaultLayoutSidebar from "../../../components/DefaultLayoutSidebar"

import illustration2x from "../../../images/illustrations/illustration-requirements-washed-red-cells@2x.png"

import { theme } from "../../../styles/theme"

const WRCIndex = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - Washed Red Cells" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h3 css={[theme.components.title, theme.components.titleRed]}>
            Washed Red Cells
          </h3>
          <ul>
            <li>
              Washed red cells are indicated for patients with recurrent or
              severe allergic or febrile reactions to red cells, and severely
              IgA-deficient patients with anti-IgA antibodies for whom red cells
              from an IgA deficient donor are not available.
            </li>
            <li>
              The decision to provide a specific patient with washed red cells
              must be made by a Consultant Haematologist. Refer any patients
              with recurrent or severe allergic reactions to the Consultant
              Haematologist for advice.
            </li>
            <li>
              Washed red cells can be used for IgA deficient patients with
              anti-IgA antibodies if red cells from IgA deficient donors are not
              available.
            </li>
            <li>
              The shelf life for both irradiated and non-irradiated automated
              washed cells is set at 14 days post washing and irradiation.
              However, production of these, in extenuating circumstances, may be
              manual with suspension in saline not SAGM. This will result in a
              24-hour shelf life component. Discuss each case with your local
              transfusion laboratory to ensure provision can be met.
            </li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default WRCIndex
